// Had to create lib directory for Panzoom library pending acceptance of PR:
// https://github.com/anvaka/panzoom/pull/50
// Still need module installed via NPM to connect dependencies
import panzoom from 'panzoom';
import { scrollTop } from '../utilities/scroll';

const INIT_ANIMATION_DELAY = 300;

export default class {
    constructor({
        id,
        startHandle,
        mapHandle,
        zoomHandle,
        panHandle,
        panelHandle,
        legendToggleHandle,
        activeClass,
        legendHiddenClass = 'legend-hidden',
        showCoords,
        state,
    }) {
        const el = document.getElementById(id);
        const start = el.querySelector(startHandle);
        const map = el.querySelector(mapHandle);
        const image = map.querySelector('img');
        const coords = map.querySelector('span');
        const zoom = el.querySelector(zoomHandle);
        const zoomControls = zoom.querySelectorAll('button');
        const zoomIn = zoomControls[0];
        const zoomOut = zoomControls[1];
        const pan = el.querySelector(panHandle);
        const panControls = pan.querySelectorAll('button');
        const panUp = panControls[0];
        const panRight = panControls[1];
        const panDown = panControls[2];
        const panLeft = panControls[3];
        const icons = Array.from(map.querySelectorAll('em'));
        const toggles = Array.from(el.querySelectorAll('[type="checkbox"][name="types"]'));
        const panels = Array.from(el.querySelectorAll(panelHandle));
        const closes = Array.from(panels.map(panel => panel.querySelector('button')))
            .filter(close => close);
        const legendToggle = el.querySelector(legendToggleHandle);

        let pz = null;

        // Helper functions
        function init() {
            pz = panzoom(map, {
                bounds: true,
                boundsPadding: 1,
                minZoom: 1,
                maxZoom: 5,
                // beforeWheel: () => true, // Ignore wheel zoom
                onTouch: e => !e.target.matches('[data-panel-index]') && !e.target.closest('[data-panel-index]'),
            });

            el.classList.add(activeClass);
            panels[0].classList.add(activeClass);

            // Compensate icon scale on map transform
            pz.on('transform', () => {
                const scale = pz.getTransform().scale || 1;

                icons.forEach(icon => {
                    icon.style.transform = `scale(${1 / scale}) translate(-${50 * scale}%, -${50 * scale}%)`;
                });
            });

            // Center image in map on start
            setTimeout(() => {
                const tx = (map.parentElement.offsetWidth - map.offsetWidth) / 2;
                const ty = (map.parentElement.offsetHeight - map.offsetHeight) / 2;

                pz.smoothMoveTo(tx, ty);
            }, INIT_ANIMATION_DELAY);
        }

        // Event handler functions
        function handleResize() {
            const contRatio = map.parentElement.offsetWidth / map.parentElement.offsetHeight;
            const imageRatio = image.naturalWidth / image.naturalHeight;

            // Reset map container and image dimensions
            map.style.width = 'auto';
            map.style.height = 'auto';
            image.style.width = 'auto';
            image.style.height = 'auto';

            // Set map and image dimensions per container aspect ratio
            if (contRatio > imageRatio) {
                map.style.width = '100%';
                image.style.width = '100%';
                image.style.marginLeft = '0';
            } else {
                map.style.width = `${image.offsetWidth}px`;
                map.style.height = '100%';
                image.style.width = `${image.offsetWidth}px`;
                image.style.height = '100%';
                image.style.marginLeft = `${(map.offsetWidth - image.offsetWidth) / 2}px`;
            }

            // Reset transform
            if (pz) {
                pz.smoothZoom(map.offsetWidth / 2, map.offsetHeight / 2, 0);
            }
        }
        function handleStart(e) {
            e.preventDefault();

            init();
            handleResize();
        }
        function handleZoomIn() {
            const { x, y } = pz.getTransform();
            const { width, height } = map.getBoundingClientRect();

            pz.smoothZoom(x + width / 2, y + height / 2, 2);
        }
        function handleZoomOut() {
            const { x, y } = pz.getTransform();
            const { width, height } = map.getBoundingClientRect();

            pz.smoothZoom(x + width / 2, y + height / 2, 0.5);
        }
        function handlePanUp() {
            pz.moveBy(0, 100, true);
        }
        function handlePanRight() {
            pz.moveBy(-100, 0, true);
        }
        function handlePanDown() {
            pz.moveBy(0, -100, true);
        }
        function handlePanLeft() {
            pz.moveBy(100, 0, true);
        }
        function handleIcon(e) {
            const icon = e.currentTarget;
            const panelIndex = parseInt(icon.getAttribute('data-panel-index'), 10);

            function cb() {
                const animationDelay = el.classList.contains(legendHiddenClass) ? 300 : 0;

                icons.forEach(i => { i.classList.toggle(activeClass, i === icon); });
                panels.forEach((panel, i) => {
                    panel.classList.toggle(activeClass, i === panelIndex);
                });
                el.classList.remove(legendHiddenClass);
                legendToggle.title = 'Hide Legend';
                // Center pz on icon
                setTimeout(() => {
                    const { offsetWidth, offsetHeight } = map.parentElement;
                    const { offsetLeft, offsetTop } = icon;
                    const { scale } = pz.getTransform();
                    const x = (-1 * offsetLeft * scale) + offsetWidth / 2;
                    const y = (-1 * offsetTop * scale) + offsetHeight / 2;

                    pz.smoothMoveTo(x, y);
                }, animationDelay);
            }

            scrollTop(el, state.navHeight, cb);
        }
        function handleToggle(e) {
            const toggle = e.currentTarget;

            icons
                .filter(i => i.getAttribute('data-type') === toggle.value)
                .forEach(i => { i.style.display = toggle.checked ? 'block' : 'none'; });
        }
        function handleClose() {
            panels.forEach((panel, i) => { panel.classList.toggle(activeClass, i === 0); });
            icons.forEach(icon => { icon.classList.remove(activeClass); });
        }
        function handleLegend() {
            legendToggle.title = el.classList.toggle(legendHiddenClass)
                ? 'Show legend'
                : 'Hide legend';
        }
        function displayCoords({ clientX, clientY }) {
            const { top, left, width, height } = image.getBoundingClientRect();
            const x = clientX - left;
            const y = clientY - top;
            const pX = (x / width) * 100;
            const pY = (y / height) * 100;
            const scale = pz.getTransform().scale || 1;

            coords.style.left = `${(x / scale) + 5}px`;
            coords.style.top = `${(y / scale) + 5}px`;
            coords.style.transform = `scale(${1 / scale})`;
            coords.textContent = `X: ${pX.toFixed(2)}%, Y: ${pY.toFixed(2)}%`;
        }

        // Add event listeners
        window.addEventListener('resize', handleResize);
        start.addEventListener('click', handleStart);
        image.addEventListener('load', handleResize);
        zoomIn.addEventListener('click', handleZoomIn);
        zoomIn.addEventListener('touchstart', handleZoomIn);
        zoomOut.addEventListener('click', handleZoomOut);
        zoomOut.addEventListener('touchstart', handleZoomOut);
        panUp.addEventListener('click', handlePanUp);
        panRight.addEventListener('click', handlePanRight);
        panDown.addEventListener('click', handlePanDown);
        panLeft.addEventListener('click', handlePanLeft);
        icons.forEach(icon => {
            icon.addEventListener('click', handleIcon);
        });
        toggles.forEach(toggle => {
            toggle.addEventListener('change', handleToggle);
        });
        closes.forEach(close => {
            close.addEventListener('click', handleClose);
        });
        legendToggle.addEventListener('click', handleLegend);

        scrollTop(el);
        handleResize();

        if (showCoords) {
            image.addEventListener('click', displayCoords);
            image.addEventListener('pointermove', displayCoords);
            image.addEventListener('pointerdown', displayCoords);
            image.addEventListener('pointerleave', () => { coords.textContent = ''; });
        }
    }
}
